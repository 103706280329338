import { type VFC } from "react";
import { Box } from "@chakra-ui/react";
import { Header } from "@wpf-ui/components/fragments/header";
import { useCurrentUser } from "@wpf-ui/contexts";
interface DefaultLayoutProps {
  readonly children: React.ReactNode;
}

export const DefaultLayout: VFC<DefaultLayoutProps> = (props: DefaultLayoutProps) => {
  const { children } = props;
  const currentUser = useCurrentUser();
  return (
    <>
      {currentUser && <Header name={currentUser.name} profilePhotoUrl={currentUser.profilePhotoUrl} />}
      <Box as="main" w={{ pc: "90%", sp: "100%" }} m="0 auto" px={{ sp: "1.5rem" }}>
        {children}
      </Box>
    </>
  );
};
