import { useCurrentUser } from "@wpf-ui/contexts";

interface Features {
  jobListing: boolean;
  sheCompetitionMvp: boolean;
  sheCompetitionGalleryView: boolean;
  writerCompetition: boolean;
}

const defaultFeatures = {
  enabledFeatures: new Set<string>(),
};

export function useFeatures(): Features {
  const { enabledFeatures } = useCurrentUser() ?? defaultFeatures;
  return {
    jobListing: enabledFeatures.has("job_listing"),
    sheCompetitionMvp: enabledFeatures.has("she_competition_mvp"),
    sheCompetitionGalleryView: enabledFeatures.has("she_competition_gallery_view"),
    writerCompetition: enabledFeatures.has("writer_competition"),
  };
}
